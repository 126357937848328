<style scoped lang="scss">
  .login-form {
    padding: 40px;
    width: 640px;

    @include media(max, md) {
      width: 100%;
      height: auto;
    }

    .form-title {
      font-size: 22px;
      line-height: 1.36;
      text-align: center;
      color: $black-five;
    }

    .login-section {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      .login-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90%;

        .login-button {
          width: 56px;
          height: 56px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 22px;
          margin: 0 10px 20px;

          img {
            max-height: 40px;
            max-width: 40px;
          }
        }
      }
    }

    .account-helper {
      margin-top: 30px;
      text-align: center;
      display: flex;
      flex-direction: column;

      .helper-link {
        display: flex;
        align-items: center;
        align-self: center;
        cursor: pointer;
        color: $pumpkin-orange;
        user-select: none;

        .text {
          font-size: 14px;
          line-height: 1.43;
        }

        .icon {
          margin-left: 7px;
          font-size: 10px;
          transition: transform .3s ease-in-out;
        }
      }

      .account-text-wrapper {
        height: 0;
        overflow: hidden;
        transition: height .3s ease-in-out;
      }

      .account-text {
        padding-top: 20px;
        max-width: 500px;
        @include margin-x(auto);
        font-size: 13px;
        max-height: 100px;
      }

      &.opened {
        .helper-link .icon {
          transform: rotate(180deg);
        }
      }
    }

    .terms-and-policy {
      margin-top: 20px;
      text-align: center;
    }

    .loading-container {
      @include position-all(absolute, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(white, .5);
    }
  }

  .last-login {
    &-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__avatar {
      border-radius: 50%;
      width: 65px;
      height: 65px;
      overflow: hidden;
      margin-top: 40px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;

      img {
        display: block;
        width: 100%;
      }
    }

    &__name {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.5;
      color: inherit;
      margin-bottom: 15px;
    }

    &__switch-all {
      color: #F57D07;
      font-size: 14px;
      line-height: 1.5;
      display: inline-block;
      cursor: pointer;
    }

    &-button {
      border-radius: 25px;
      margin-bottom: 25px;
      background-image: none;

      svg {
        margin-right: 10px;
      }
    }
  }

</style>

<template>
  <modal v-model="loginModalOpened">
    <div slot="body" class="login-form">
      <div v-if="loadingState.auth" class="loading-container">
        <loader size="lg"/>
      </div>
      <div v-if="lastLoginLink">
        <div class="form-title">{{ $t('global.lastLoginTitle') }}</div>

        <div class="last-login-section">
          <div class="last-login__avatar">
            <img :src="lastLogin.avatar" alt="">
          </div>
          <div class="last-login__name">{{ lastLogin.name }}</div>
          <div>
            <btn
              variant="primary"
              @click="onAuthLinkClick(lastLoginLink.url)"
              :href="lastLoginLink.href"
              class="last-login-button"
              :class="lastLoginLink.classes"
            >
              <icon :name="lastLoginLink.icon"/>
              <span>{{
                  $t('global.lastLoginButton')
                }} {{
                  $t('global.login.platforms.' + (lastLoginLink.platform))
                }}</span>
            </btn>
          </div>
          <span class="last-login__switch-all" @click="switchToAllPlatforms">{{
              $t('global.switchToAllPlatforms')
            }}</span>
        </div>
      </div>

      <div v-else>
        <div class="form-title">{{ $t('global.login.choosePlatform') }}</div>

        <div class="login-section">
          <div class="login-buttons">
            <template  v-for="(link, index) in socialLinksList">
              <a
                :key="index"
                :href="link.href"
                class="login-button"
                :class="link.classes"
                @click="onAuthLinkClick(link.url)">
                <img v-if="link.image" :src="link.image"/>
                <icon v-else :name="link.icon"/>
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="account-helper" :class="{opened: helperTextVisible}" @click="toggleHelperText">
        <div class="helper-link">
          <span class="link-text">{{ $t('global.login.accountExists.title') }}</span>
          <icon name="angle-down"/>
        </div>
        <div ref="account-text-wrapper" class="account-text-wrapper">
          <div ref="account-text" class="account-text">
            {{ $t('global.login.accountExists.text') }}
          </div>
        </div>
      </div>
      <terms-and-policy/>
    </div>
  </modal>
</template>

<script>

import TermsAndPolicy from './TermsAndPolicy'
import { i18n } from '@src/localization/config'

export default {
  name: 'LoginModal',
  components: { TermsAndPolicy },
  data() {
    return {
      loadingState: {
        auth: false,
      },

      helperTextVisible: false,
    }
  },
  computed: {
    ...mapFields('viewer', ['loginModalOpened']),
    ...mapGetters('creator', ['getCreatorName']),
    ...mapGetters('login', ['lastLogin']),

    lastLoginLink() {
      return this.lastLogin
        ? this.socialLinks.find(link => link.platform === this.lastLogin.platform)
        : null
    },

    socialLinks() {
      const redirectUrl = `${window.location.origin}/r/${this.getCreatorName}`

      return [
        {
          url: 'twitch',
          platform: 'twitch',
        },
        {
          url: 'youtube',
          platform: 'youtube-official',
          image: this.$asAppAsset('/images/social/youtube_social_circle_red.png'),
        },
        {
          url: 'vk-user',
          platform: 'vk-user',
          hidden: i18n.locale !== 'ru_RU',
        },
        {
          url: 'ok',
          platform: 'ok',
          hidden: i18n.locale !== 'ru_RU',
        },
        {
          url: 'vkplaylive',
          platform: 'vkplaylive',
          hidden: i18n.locale !== 'ru_RU',
        },
        {
          url: 'facebook',
          platform: 'facebook',
        },
        {
          url: 'dailymotion',
          platform: 'dailymotion',
        },
        {
          url: 'discord',
          platform: 'discord',
        },
        {
          url: 'boosty',
          platform: 'boosty',
        },
        {
          url: 'trovo',
          platform: 'trovo',
        },
        {
          url: 'tiktok',
          platform: 'tiktok',
        },
        {
          url: 'telegram',
          platform: 'telegram',
        },
        {
          url: 'goodgame',
          platform: 'goodgame',
        },
        {
          url: 'appleid',
          platform: 'appleid',
        },
      ].map(link => ({
        ...link,
        href: `/auth/${link.url}?viewer=1&redirectTo=${redirectUrl}`,
        icon: `social-${link.platform}`,
        classes: [`social-${link.platform}`],
      }))
    },

    socialLinksList() {
      return this.socialLinks.filter(link => !link.hidden)
    },
  },
  methods: {
    ...mapActions('login', ['getLastLoginInfo', 'clearLastLoginInfo']),

    toggleHelperText() {
      this.helperTextVisible = !this.helperTextVisible

      const { height } = this.$refs['account-text'].getBoundingClientRect()

      if (this.helperTextVisible) {
        this.$refs['account-text-wrapper'].style.height = `${height}px`
      } else {
        this.$refs['account-text-wrapper'].style.height = '0px'
      }
    },

    onAuthLinkClick(platform) {
      this.$ga.action({
        category: 'Auth',
        action: `btn_${platform}`,
      })

      this.$tmr.goal('auth', {
        platform: platform,
      })

      this.loadingState.auth = true
    },

    switchToAllPlatforms() {
      this.clearLastLoginInfo()
    },
  },
  created() {
    this.getLastLoginInfo()
  },
}
</script>
